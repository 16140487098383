import { ADD_TO_CART } from '@scandipwa/scandipwa/src/util/Product';
import { magentoProductTransform } from '@scandipwa/scandipwa/src/util/Product/Transform';
import { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import { RootState } from 'Util/Store/Store.type';

/** @namespace Pwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    ...sourceMapDispatchToProps(dispatch),
});

/** @namespace Pwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    containerProps() {
        const {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            product,
            quantity,
        } = this.props;

        const {
            isAdding,
        } = this.state;

        return {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            isAdding,
            product,
            quantity,
        };
    }

    handleButtonClick(e: MouseEvent): void {
        e.stopPropagation();
        e.preventDefault();

        this.addProductToCart();
    }

    async addProductToCart(): Promise<void> {
        const { product, addToCart, updateSelectedValues } = this.props;

        if (updateSelectedValues) {
            await updateSelectedValues();
        }

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        this.setState({ isAdding: true });

        if (!this.validate()) {
            return;
        }

        // if (typeof addToCart === 'function') {
        //     try {
        //         await addToCart();
        //     } finally {
        //         this.setState({ isAdding: false });
        //     }
        // } else {
        const {
            quantity,
            cartId,
            fallbackAddToCart,
        } = this.props;
        const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

        try {
            await fallbackAddToCart({
                products: magentoProduct,
                cartId,
            });
        } finally {
            this.setState({ isAdding: false });
        }
        // }

        this.setState({ isAdding: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
