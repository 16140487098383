/* eslint-disable max-len */
import {
    CartIconComponent as SourceCartIconComponent,
} from 'SourceComponent/CartIcon/CartIcon.component';
import { ReactElement } from 'Type/Common.type';

import './CartIcon.override.style';

/** @namespace Pwa/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIconComponent {
    render(): ReactElement {
        const { isActive } = this.props;

        return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.121"
              height="16.02"
              viewBox="0 0 20.121 16.02"
              fill="none"
              block="CartIcon"
              mods={ { isActive } }
            >
                <g id="Raggruppa_175587" data-name="Raggruppa 175587" transform="translate(-1035 -413.838)">
                    <g id="Raggruppa_175102" data-name="Raggruppa 175102">
                        <path id="Sottrazione_3" data-name="Sottrazione 3" d="M21880,20949a3,3,0,1,1,3-3A3.007,3.007,0,0,1,21880,20949Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,21880,20945Z" transform="translate(-20837.996 -20519.145)" />
                        <path id="Sottrazione_4" data-name="Sottrazione 4" d="M21880,20949a3,3,0,1,1,3-3A3.007,3.007,0,0,1,21880,20949Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,21880,20945Z" transform="translate(-20828.996 -20519.145)" />
                        <path id="Tracciato_51113" data-name="Tracciato 51113" d="M-20828.482-20484.58h-9.543l-.254-.623-3.758-9.213H-20845v-2h4.311l.254.623,3.758,9.213h6.828l2.049-5.285h-8.549v-2h11.471l-.529,1.361Z" transform="translate(21880.004 20910.254)" />
                    </g>
                </g>
            </svg>

        );
    }
}

export default CartIconComponent;
