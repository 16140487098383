import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { PopupContainerProps } from 'Component/Popup/Popup.type';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer,
} from 'SourceComponent/Popup/Popup.container';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import SidePopupComponent from './SidePopup.component';

/** @namespace Pwa/Component/SidePopup/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/SidePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/SidePopup/Container */
export class SidePopupContainer extends SourcePopupContainer {
    static defaultProps: Partial<PopupContainerProps> = {
        isHistoryBackHandle: false,
    };

    render(): ReactElement {
        return (
            <SidePopupComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePopupContainer);
