/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import history from '@scandipwa/scandipwa/src/util/History';
import { createRef, MouseEvent } from 'react';
import { createPortal } from 'react-dom';

import { PopupComponentProps } from 'Component/Popup/Popup.type';
import {
    PopupComponent as SourcePopupComponent,
} from 'SourceComponent/Popup/Popup.component';

import './SidePopup.override.style';

/** @namespace Pwa/Component/SidePopup/Component */
export class SidePopupComponent extends SourcePopupComponent {
    static defaultProps: Partial<PopupComponentProps> = {
        isCloseOnOutsideClick: true,
        title: '',
        isHistoryBackHandle: false,
    };

    state = {
        visible: false,
    };

    contentRef = createRef<HTMLDivElement>();

    componentDidMount(): void {
    }

    componentDidUpdate(): void {
    }

    componentWillUnmount(): void {
    }

    onVisible(): void {
    }

    onHide(): void {
    }

    hidePopupAndGoBack(e?: MouseEvent): void {
        const { isHistoryBackHandle } = this.props;
        const delay = 300;
        e?.preventDefault();
        e?.stopPropagation();
        this.contentRef.current?.classList.remove('Popup-Content_isVisible');
        setTimeout((): void => this.hidePopUp(), delay);

        if (isHistoryBackHandle) {
            history.goBack();
        }
    }

    hidePopUp(): void {
        const { hideActiveOverlay } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            hideActiveOverlay();
        }
    }

    renderContent(): ReactElement {
        const { children, contentMix } = this.props;
        const { visible } = this.state;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            this.setState({ visible: false });

            return null;
        }
        const delay = 100;
        setTimeout((): void => this.setState({ visible: true }), delay);

        return (
            <div
              block="Popup"
              elem="Content"
              mix={ contentMix }
              mods={ { isVisible: visible } }
              ref={ this.contentRef }
            >
                <header block="Popup" elem="Header">
                    { this.renderTitle() }
                    { this.renderCloseButton() }
                </header>
                { children }
            </div>
        );
    }

    preventRedirect = (e: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (this.contentRef.current && !this.contentRef.current.contains(e.target as Node)) {
            // Verifica se il click è avvenuto al di fuori del contenuto
            this.hidePopupAndGoBack(e);
        }
    };

    render(): ReactElement {
        const { mix, areOtherOverlaysOpen } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen, isSidePopup: true } }
              mix={ { ...mix, mods: { ...mix?.mods, isVisible } } }
              onClick={ this.preventRedirect }
            >
                { this.renderContent() }
            </div>,
            document.body,
        );
    }
}

export default SidePopupComponent;
