/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import AddToCartPopup from 'Component/AddToCartPopup';
import CartIcon from 'Component/CartIcon';
import If from 'Component/If';
import {
    AddToCartComponent as SourceAddToCartComponent,
} from 'SourceComponent/AddToCart/AddToCart.component';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

import { AddToCartComponentProps } from './AddToCart.type';

import './AddToCart.override.style';

/** @namespace Pwa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCartComponent {
    props!: AddToCartComponentProps;

    clickButton = (e: React.MouseEvent) => {
        const {
            product,
            handleButtonClick,
        } = this.props;

        handleButtonClick(e);
        localStorage.setItem('popup_sku', product?.sku || '');
    };

    render(): ReactElement {
        const {
            product,
            quantity,
            mix,
            layout,
            isDisabled,
            isAdding,
            isIconEnabled,
        } = this.props;

        if (!isSignedIn()) {
            return null;
        }

        return (
            <>
                <AddToCartPopup product={ product } quantity={ quantity } />
                <If condition={ Boolean(isIconEnabled) }>
                    <button
                      onClick={ this.clickButton }
                      block="ProductAddToCart"
                      disabled={ isDisabled || isAdding }
                    >
                        <CartIcon />
                    </button>
                </If>
                <If condition={ !isIconEnabled }>
                    <button
                      onClick={ this.clickButton }
                      block="Button AddToCart"
                      mix={ mix }
                      mods={ { layout } }
                      disabled={ isDisabled || isAdding }
                    >
                        <CartIcon />
                        <span>{ isAdding ? __('Adding...') : __('Add to cart') }</span>
                    </button>
                </If>
            </>
        );
    }
}

export default AddToCartComponent;
