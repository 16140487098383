import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { ReactElement } from 'Type/Common.type';

import AddToCartPopupComponent from './AddToCartPopup.component';
import { AddToCartPopupContainerProps } from './AddToCartPopup.type';

/** @namespace Pwa/Component/AddToCartPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/AddToCartPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
});

/** @namespace Pwa/Component/AddToCartPopup/Container */
export class AddToCartPopupContainer extends PureComponent<AddToCartPopupContainerProps> {
    containerFunctions = {
    };

    containerProps() {
        const {
            product,
            quantity,
        } = this.props;

        return {
            product,
            quantity,
        };
    }

    render(): ReactElement {
        return (
            <AddToCartPopupComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartPopupContainer);
