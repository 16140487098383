import { ProductType } from 'Component/Product/Product.config';
import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { FormattedMoney } from 'Util/Product/Product.type';

import './ProductPrice.override.style';

/** @namespace Pwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    renderPriceWithOrWithoutTax(
        basePrice: Partial<FormattedMoney>,
        taxPrice: Partial<FormattedMoney>,
        label?: string | ReactElement,
    ): ReactElement {
        // ONLY INCLUDE TAX IN PRICE
        // if (tax_display_type === DisplayProductPricesInCatalog.INCL_TAX) {
        return this.renderPrice(basePrice, label);
        // }

        // if (tax_display_type === DisplayProductPricesInCatalog.EXCL_TAX) {
        //     return this.renderPrice(taxPrice, label);
        // }

        // return (
        //     <>
        //         { this.renderPrice(basePrice, label) }
        //         { this.renderSubPrice(taxPrice) }
        //     </>
        // );
    }

    renderTierPrice(): ReactElement {
        return (
            null
        );
    }

    render(): ReactElement {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!isSignedIn()) {
            return null;
        }

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== ProductType.BUNDLE && this.renderTierPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
